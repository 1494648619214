import type DataObject from 'o365.modules.DataObject.ts';
import type { ItemModel } from 'o365.modules.DataObject.Types.ts';
import type { DataObjectDefinitionFieldType } from 'o365.modules.DataObject.Fields.ts'

import API from 'o365.modules.data.api.ts';
import getTreeObject from 'o365.modules.TreeObject.ts';
import { TreeObject, TreeNode } from 'o365.modules.TreeObject.ts';
//import {createDataObject} from 'o365.vue.ts';
//import {ref} from 'vue';
import context from 'o365.modules.Context.ts';

type GroupByFieldConfiguration = {
    Position: number,
    FieldName: string,
    FieldValue: string,
    ViewName: string,
    ID: number,
    PrimKey: string,
}

type GroupByItemModel = ItemModel & { Count: number };

export default class GroupByFolder<T extends ItemModel = ItemModel> {
    private _dataObject: DataObject<T>;
    private _folders: Array<GroupFolder> = [];
    private _prevWhereClause: string;
    private _initialized = false;
    current?: GroupFolder;
    currentGroupByFilter?: string[];
    currentPrettyGroupByFilter?: string;
    accessIdPathField: string;

    private _cancelTokens: (()=>void)[] = [];

    get folders() {
        return this._folders;
    }

    get whereClause():string {
        return this.combinedWhereClause(this._dataObject.recordSource.whereClause, this._dataObject.masterDetails.getFilterString())
    }

    listGroupFields: Map<number, GroupByFieldConfiguration[]> = new Map();
    groupData?: Map<string, T[]>;

    constructor(pDataObject: DataObject<T>, pAcessIdPathString: string) {
        this._dataObject = pDataObject;
        if(this._dataObject.recordSource.contextFilterSet) {
            this.accessIdPathField = pAcessIdPathString;
            this._prevWhereClause = '(' + this.whereClause + ')' + ' AND ' + this._dataObject.recordSource.getContextFilterString()
        } else {
            this.accessIdPathField = '';
            this._prevWhereClause = this.whereClause;
        }
        
    }

    initialize() {
        if (this._initialized) { return; }
        this._initialized = true;
        this._cancelTokens.push(this._dataObject.on('BeforeLoad', (pOptions) => {
            if (pOptions.skip && pOptions.skip > 0) {
                return;
            }
            if (this.combinedWhereClause(pOptions.whereClause!, this._dataObject.masterDetails.getFilterString()) != this._prevWhereClause) {
                this._prevWhereClause = pOptions.whereClause!;
                if (this._dataObject.masterDetails.getFilterString()) {
                    this.reloadWhereClause();
                }
            }
        }))
        this._cancelTokens.push(this._dataObject.on('AfterSave', (pOptions) => {
          //  if (pOptions.skip && pOptions.skip > 0) {
            //    return;
          //  }
            this.reloadWhereClause();
           /* if (this.combinedWhereClause(pOptions.whereClause!, this._dataObject.masterDetails.getFilterString()) != this._prevWhereClause) {
                this._prevWhereClause = pOptions.whereClause!;
                if (this._dataObject.masterDetails.getFilterString()) {
                    this.reloadWhereClause();
                }
            }*/
        }))
    }

    dispose() {
        this._cancelTokens.splice(0, this._cancelTokens.length).forEach(ct => ct());
    }

    selectGroupByByIndex(pId: number) {
        return this.selectGroupBy(this.folders[pId]);
    }

    async selectGroupBy(sel: GroupFolder) {
        await sel.loadConfig();
        if (sel.treeObject == null) { return; }
        sel.treeObject.loading = true;
        this.current = sel;
        await this.expand(sel.treeObject.node);
        sel.treeObject.loading = false;
    }

    async initFolders(pIndexToSelect?: number) {
        this._folders = [];
        const data = await request<{ ID: number, Name: string }>({
            viewName: 'sviw_Database_GroupByFolders',
            fields: [
                { name: 'ID', type: 'number' },
                { name: 'Name', sortOrder: 1, sortDirection: 'asc' }
            ],
            whereClause: `[ViewName] = '${this._dataObject.viewName}'`
        });
        const uniqueField = this._dataObject.fields.uniqueField;
        data.forEach(item => {
            this._folders.push(new GroupFolder(item, this.accessIdPathField, uniqueField));
        });
        if (pIndexToSelect != null && this._folders?.length > 0) {
            return this.selectGroupByByIndex(pIndexToSelect);
        }
        return this._folders;
    }

    async expand(pNode: TreeNode<NodeItem>) {
        if (this.current == null) { return; }
        let vConfig = this.current.fieldsConfigs.get(1);
        try {
            pNode.loading = true;
            if (pNode.item == null && this.current.node && vConfig) {
                if (this.current.treeObject && this.current.treeObject.node.children.length > 0) { return; }
                const children = await this.current.node.getChildren(vConfig, this.whereClause);
                pNode.addChildren(children);
                if (this.currentGroupByFilter) {
                    this.current.treeObject?.node.children.forEach(child => {
                        if (child.item && this.currentGroupByFilter!.indexOf(child.item.id) !== -1) {
                            this.expand(child);
                        }
                    });
                }
            } else if (pNode.item) {
                vConfig = this.current.fieldsConfigs.get(pNode.item.config.position + 1);
                if (vConfig) {
                    if (!pNode.hasChildren) {
                        const children = await pNode.item.getChildren(vConfig, this.whereClause);
                        pNode.addChildren(children);
                        if (this.currentGroupByFilter) {
                            this.current.treeObject?.node.children.forEach(child => {
                                if (child.item && this.currentGroupByFilter!.indexOf(child.item.id) !== -1) {
                                    this.expand(child);
                                }
                            });
                        }
                    }
                }
            }
        } finally {
            pNode.loading = false;
        }
    }

    applyGroupByFilter(pNode: TreeNode<NodeItem>) {
        if (pNode.item) {
            this.currentGroupByFilter = pNode.item.groupByFilter;
            this.currentPrettyGroupByFilter = pNode.item.prettyGroupByFilter;
            this._dataObject.recordSource.groupByFilter = pNode.item.groupByFilter?.join(' AND ');
            
        }
        this._dataObject.load();
    }

    resetGroupByFilter() {
        this.currentGroupByFilter = undefined;
        this.currentPrettyGroupByFilter = undefined;
        if (this._dataObject.recordSource.groupByFilter) {
            this._dataObject.recordSource.groupByFilter = undefined;
            this._dataObject.load();
        }
    }

    async reloadWhereClause() {
        const vIndex = this._folders.findIndex(x => x.id == this.current?.id);
        this._folders.forEach(folder => {
            folder.treeObject?.resetNode();
        })

        await this.initFolders(vIndex);
    }

    async reloadAllNodes() {
        if (this.current == null) { return; }
        await this.selectGroupBy(this.current);
    }

    combinedWhereClause(rWhereClause: string|null, masterDetailsFString: string|null): string {
        const vReturn = [];
        if(rWhereClause){
            vReturn.push(rWhereClause);
        }
        if(masterDetailsFString){
            vReturn.push(masterDetailsFString);
        }
        return vReturn.join(" AND ");
    }
}

class GroupFolder {
    id: number;
    name: string;
    primKey: string;
    fieldsConfigs: Map<number, FolderConfig> = new Map();
    treeObject?: TreeObject<NodeItem>;
    accessIdPathField: string;
    node?: NodeItem;
    private _countField?: string;

    constructor(pItem: any, accessIdPathField: string, pCountField?: string) {
        this.id = pItem.ID;
        this.name = pItem.Name;
        this.primKey = pItem.PrimKey;
        this.accessIdPathField = accessIdPathField
        this._countField = pCountField;
    }
    /** load current config when created*/
    async loadConfig() {
        const data = await request<GroupByFieldConfiguration>({
            viewName: 'stbv_Database_GroupByFoldersFields',
            whereClause: `[GroupByFolder_ID] = ${this.id}`,
            fields: [
                { name: 'Position', type: 'number', sortOrder: 1, sortDirection: 'asc' }, 
                { name: 'FieldName' }, 
                { name: 'FieldValue' }, 
                { name: 'ViewName' }, 
                { name: 'ID', type: 'number' },
            ]
        });
        data.forEach(item => {
            this.fieldsConfigs.set(item.Position, new FolderConfig(item, data.length, this._countField));
        });
        const treeObject = getTreeObject<NodeItem>({ id: this.id });
        if (treeObject == null) {
            return;
        }
        this.treeObject = treeObject;
        this.treeObject.loading = true;
        this.node = new NodeItem(null, this.fieldsConfigs.get(1)!, this.accessIdPathField, undefined);
        return this.fieldsConfigs;
    }
}

class FolderConfig {
    id: number;
    fieldName: string;
    fieldValue: string;
    countField: string;
    position: number;
    primKey: string;
    viewName: string;
    length: number

    get fields(): DataObjectDefinitionFieldType[] {
        let result: DataObjectDefinitionFieldType[] = [
            { name: this.fieldName, sortOrder: 1, sortDirection: 'asc', groupByOrder: 1 },
            { name: this.countField, alias: 'Count', aggregate: 'COUNT' }
        ]
        if (this.fieldName !== this.fieldValue) {
            result.push({ name: this.fieldValue, groupByOrder: 1 })
        }
        return result;
    }

    constructor(pOptions: GroupByFieldConfiguration, pLength: number, pCountField?: string) {
        this.id = pOptions.ID;
        this.fieldName = pOptions.FieldName;
        this.fieldValue = pOptions.FieldValue;
        this.position = pOptions.Position;
        this.primKey = pOptions.PrimKey;
        this.viewName = pOptions.ViewName;
        this.length = pLength;
        this.countField = pCountField ?? this.fieldName;
    }
}

class NodeItem {
    //config:FolderConfig;
    private _groupByFilter?: string[];
    private _prettyGroupByFilter?: string;
    config: FolderConfig;
    item?: GroupByItemModel;
    accessIdPathField?: string;
    parent?: NodeItem;
    id: string;

    get filterString() {
        const vWhereClause = [];
        if (this.accessIdPathField) {
            vWhereClause.push(`[${this.accessIdPathField}] LIKE '${context.idPath}%'`);
        }
        this.getWhereClause(vWhereClause);
        return vWhereClause.join(' AND ');
    }

    get groupByFilter() {
        return this._groupByFilter;
    }
    get prettyGroupByFilter() {
        return this._prettyGroupByFilter;
    }

    get expandable() {
        return this.config.position < this.config.length;
    }

    get value() {
        if (!this.item) return null;
        return this.config ? this.item[this.config.fieldName] : null;
    }
    get key() {
        if (!this.item) return null;
        return this.config ? this.item[this.config.fieldValue] : null;
    }
    get count() {
        return this.item?.Count;
    }

    constructor(pItem: GroupByItemModel | null, pConfig: FolderConfig, pAccessIdPathField?: string, pParent?: NodeItem) {
        this.config = pConfig;
        if (pItem) {
            this.item = pItem;
        }
        this.accessIdPathField = pAccessIdPathField;
        this.parent = pParent;
        this._groupByFilter = this.getGroupByFilter([]);
        this._prettyGroupByFilter = this.getPrettyGroupByFilter([]).join(' AND ');
        this.id = this._getFilterExpression(this.config.fieldValue, this.key);

    }

    getWhereClause(pFilterArr: string[]) {
        if (this.parent) {
            this.parent.getWhereClause(pFilterArr);
        }
        if (this.item) {
            pFilterArr.push(this._getFilterExpression(this.config.fieldValue, this.key));
        }
        return pFilterArr;
    };

    getGroupByFilter(pFilterArr: string[]) {
        if (this.parent) {
            this.parent.getGroupByFilter(pFilterArr);
        }
        if (this.item) {
            pFilterArr.push(this._getFilterExpression(this.config.fieldValue, this.key));
        }
        return pFilterArr;
    }

    getPrettyGroupByFilter(pFilterArr: string[]) {
        if (this.parent) {
            this.parent.getPrettyGroupByFilter(pFilterArr);
        }
        if (this.item) {
            pFilterArr.push(this._getFilterExpression(this.config.fieldName, this.value));
        }
        return pFilterArr;
    }

    async getChildren(pConfig: FolderConfig, pWereClause?: string) {
        const data = await request<GroupByItemModel>({
            viewName: pConfig.viewName,
            whereClause: pWereClause,
            filterString: this.filterString,
            fields: pConfig.fields
        });
        return data.map(item => new NodeItem(item, pConfig, this.accessIdPathField, this));
    }

    private _getFilterExpression(pColumn: string, pValue: any) {
        // if (pValue == null) {
        //     return `ISNULL([${pColumn}], '') = ''`;
        // } else {
        //     return `[${pColumn}] = '${pValue}'`;
        // }
        if(pValue == null){
            return `ISNULL([${pColumn}], '') = ''`;
        }else if (typeof pValue === 'string') {
            return `[${pColumn}] = '${this._escapeValue(pValue)}'`;
        } else {
            return `[${pColumn}] = ${pValue}`;
        }
    }

    private _escapeValue(pValue:string){
 
        if(pValue){
            return pValue.replaceAll("'","''");
        }
        return pValue;
    }
}

async function request<T extends ItemModel = ItemModel>(pOptions: {
    viewName: string,
    whereClause?: string,
    filterString?: string,
    fields: DataObjectDefinitionFieldType[]
}) {
    const data = await API.requestPost(`/api/data/${pOptions.viewName}`, JSON.stringify({
        operation: 'retrieve',
        viewName: pOptions.viewName,
        whereClause: pOptions.whereClause,
        filterString: pOptions.filterString,
        fields: pOptions.fields
    }));
    return data as T[];
}